@import '../../../styles/variables';

.burgerMenu {
  bottom: 1px;
  width: 7.3rem;
  height: 7.3rem;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  display: none;
  @media #{$max-m2} {
    display: flex;
  }
  @media #{$max-m} {
    width: 7.3rem;
    height: 7.3rem;
  }

  &-icon {
    width: 2.5rem;
    height: 1.9rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    transition: transform 0.5s $cubic-out;
    // transform: translateY(0.2rem);
    z-index: 2;
    cursor: pointer;
    // @media #{$max-s} {
    //   margin-left: 1.25rem;
    // }
    &::before,
    &::after,
    div {
      content: '';
      background-color: $dark-grey2;
      height: 4px;
      transition: transform 0.5s $cubic-out;
      border-radius: 1rem;
    }

    &.isOpened {
      z-index: 3;
      transition: transform 0.5s $cubic-out;
      width: 2.5rem;
      height: 1.9rem;
      &::before,
      &::after,
      div {
        transition: background-color 0.5s $cubic-out;
        background-color: $dark-grey2;
      }
      &:hover {
        &::before,
        &::after,
        div {
          background-color: #434e58;
        }
      }
      &::before {
        transform: translateY(0.87rem) rotate(135deg);
      }
      &::after {
        transform: translateY(-0.8rem) rotate(-135deg);
        @media #{$max-xs} {
          transform: translateY(-0.7rem) rotate(-135deg);
        }
      }
      div {
        transform: scale(0);
      }
    }
  }
}
