// @use 'variables' as *;

/**
  Buttons
  ===================================================================
*/
.btn {
  padding: 0.8rem 3rem;
  color: #fff;
  border-radius: 5rem;
  cursor: pointer;
  transition: opacity 0.3s ease-out;
  &:hover,
  &.disabled {
    opacity: 0.6;
  }
  &.disabled {
    cursor: default;
  }
  &.btn-secondary {
    background-color: #fff;
    color: #000;
    border: 1px solid #000;
  }
  &.btn-primary {
    background-color: #000;
    color: #fff;
    border: 1px solid #000;
  }
}
