@use 'variables' as *;

// @use 'variables' as *;

/**
  Form
  ===================================================================
*/

select {
  appearance: none;
  background-color: transparent;
  border: none;
  padding: 0 1em 0 0;
  margin: 0;
  width: 100%;
  font-family: inherit;
  font-size: inherit;
  cursor: inherit;
  line-height: 1.7;
  outline: none;
  z-index: 1;
  &::-ms-expand {
    display: none;
  }
  background-image: url("data:image/svg+xml,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' x='0px' y='0px' width='16px' height='9px' viewBox='0 0 16 9'%3E%3Cpolyline style='fill:none;stroke:%23aaaaaa;stroke-width:2;stroke-linecap:round;stroke-miterlimit:10;' points='15,1 8,7.6 1,1 '/%3E%3C/svg%3E%0A");
  background-repeat: no-repeat;
  background-position: center right;
  background-origin: content-box;
}

.select {
  display: grid;
  grid-template-areas: 'select';
  align-items: center;
  position: relative;

  min-width: 8rem;
  max-width: 21rem;

  border: 1px solid rgba(0, 0, 0, 0.25);
  border-radius: 0.5rem;
  padding: 1rem;

  font-size: 1.073rem;
  cursor: pointer;
  line-height: 1.1;
  background-color: #fff;
}

// Interim solution until :focus-within has better support
select:focus + .focus {
  position: absolute;
  top: -1px;
  left: -1px;
  right: -1px;
  bottom: -1px;
  border: 2px solid rgba(0, 0, 0, 0.25);
  border-radius: inherit;
}

.select--disabled {
  cursor: not-allowed;
  background-color: #eee;
  background-image: linear-gradient(to top, #ddd, #eee 33%);
}

label {
  font-size: 1rem;
}

.select + label {
  margin-top: 2rem;
}

.form-action {
  display: flex;
  justify-content: center;
}

input[type='checkbox'] {
  -webkit-appearance: none;
  appearance: none;
  background-color: #fff;
  margin: 0;
  font: inherit;
  color: currentColor;
  width: 1.15em;
  height: 1.15em;
  border: 0.15em solid grey;
  border-radius: 0.15em;
  transform: translateY(-0.075em);
  display: grid;
  place-content: center;
  &::before {
    content: '';
    width: 0.65em;
    height: 0.65em;
    transform: scale(0);
    transition: 120ms transform ease-in-out;
    box-shadow: inset 1em 1em grey;
  }
}
input[type='checkbox']:checked::before {
  transform: scale(1);
}
