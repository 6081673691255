@import '../../styles/variables';

header {
  height: 8rem;
  padding-top: 2rem;
  padding-bottom: 2rem;

  width: 100%;
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  z-index: 4;
  background-color: rgba(255, 255, 255, 0.95);
  transition: transform 0.8s $cubic-out, height 0.8s $cubic-out;

  .container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100%;
  }
  // @media #{$max-l} {
  //   height: 5.5rem;
  // }
  // @media #{$max-m} {
  //   padding: 1rem 4rem;
  //   height: 5rem;
  // }
  // @media #{$max-s} {
  //   height: 7rem;
  //   padding: 1rem 3rem;
  // }
  .logo {
    max-width: 22rem;
    max-height: 3.4rem;
    min-width: 10rem;
    width: 100%;
    height: 100%;
    color: $grey-2;
    transition: color 0.8s $cubic-out, transform 0.8s $cubic-out;
    transform-origin: left;
    &:hover {
      color: $yellow;
    }
    svg {
      width: 100%;
      height: 100%;
    }
  }
  &.isCollapsed {
    transform: translateY(-2rem);
    height: 7rem;
    .logo {
      transform: scale(0.9) translate(-1rem, 1.2rem);
    }
    nav {
      transform: translateY(1rem) scale(0.8);
      transform-origin: right;
    }
  }
}
.mobileNav {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  background-color: rgba(255, 255, 255, 0.96);
  height: 100vh;
  transform: translateY(-100%);
  transition: transform 0.5s $cubic-out, opacity 0.5s $cubic-out;
  z-index: 3;
  &.navIsOpen {
    transform: translateY(0);
    opacity: 1;
    display: grid;
    place-content: center;
    gap: 2rem;
    grid-template-columns: 1fr;
    .nav {
      display: flex;
      height: 100%;
      flex-direction: column;
      gap: 2rem;
      .navItems {
        gap: 2rem;
        &-item {
          font-size: 3.2rem;
          text-align: center;
          margin-right: 0;

          @media #{$max-m2} {
            font-size: 2.5rem;
          }
          @media #{$max-s} {
            font-size: 2rem;
          }
          &-submenu {
            position: relative;
            top: unset;
            padding: 1.5rem 1rem;
            background-color: unset;
            border: none;
            // border-radius: 0.3rem;
            width: unset;
            display: flex;
            flex-direction: column;
            gap: 1rem;
            &-item {
              text-transform: uppercase;
            }
          }
        }
      }
      .langItems {
        &-item {
          font-size: 3rem;
          text-align: center;
          margin-right: 0;
          @media #{$max-s} {
            font-size: 2rem;
          }
        }
      }
    }
  }
}
