@import '../../../styles/variables';

nav {
  display: flex;
  color: $black;
  transition: transform 0.8s $cubic-out;
  @media #{$max-m2} {
    display: none;
  }
  .navItems,
  .langItems {
    display: flex;
    @media #{$max-m2} {
      flex-direction: column;
      align-items: center;
    }
    &-item {
      margin-right: 2.938rem;
      font-size: 1.5rem;
      transition: color 0.3s $cubic-out;
      text-transform: uppercase;
      font-weight: $light;
      position: relative;
      overflow: hidden;
      cursor: pointer;
      @media #{$max-m} {
        font-size: 1.2rem;
      }
      &:last-child {
        margin-right: 0;
      }
      &::after {
        position: absolute;
        content: '';
        width: 0;
        height: 2px;
        background-color: $white;
        bottom: -5px;
        left: 0;
        transition: width 0.6s $cubic-out, background-color 0.6s $cubic-out;
      }
      &:hover {
        color: $blue;
        overflow: unset;
        &::after {
          width: 100%;
          height: 3px;
          background-color: $blue-grey;
        }
        &-submenu {
          position: absolute;
        }
      }

      &.selected {
        cursor: default;
        color: $blue-grey;
      }

      &-submenu {
        position: absolute;
        top: 1.6rem;
        padding: 1.5rem 1rem;
        background-color: rgba(255, 255, 255, 0.9);
        border: 1px solid rgba(0, 0, 0, 0.1);
        border-radius: 0.3rem;
        width: 120%;
        display: flex;
        flex-direction: column;
        gap: 1rem;
        &-item {
          color: $black;
          font-size: 1.3rem;
          // font-weight: $light;
          // padding-top: 0.3rem;
          // padding-bottom: 0.3rem;
          text-transform: none;
          color: $dark-grey;
          &:hover {
            color: $blue;
          }
        }
      }
    }
  }
  .langItems {
    &-item {
      font-size: 1.1875rem;
      font-weight: $bold;
      display: flex;
      align-items: center;
      grid-gap: 0.3rem;
      cursor: pointer;
      position: relative;
      overflow: hidden;
      @media #{$max-m2} {
        flex-direction: column;
        align-items: center;
      }

      &-selected {
      }
      &-arrow {
        display: none;
        width: 20px;
        height: 12px;
        color: $yellow-2;
        svg {
          width: 100%;
          height: 100%;
          fill: currentColor;
        }
      }
    }
  }
}
