@import '../../../styles/variables';

.tomeisSection {
  padding-top: 4rem;
  padding-bottom: 7rem;

  &-title {
    max-width: 50rem;
    font-size: 2rem;
    font-weight: $light;
    position: relative;
    margin-bottom: 2rem;
    text-transform: uppercase;
    &::after {
      content: '';
      position: absolute;
      left: 0;
      bottom: -0.3rem;
      border: 1px solid $black;
      width: 1.4rem;
      height: 1px;
      transition: width 0.5s $cubic-out;
      opacity: 0.4;
    }
  }

  .tomeis {
    display: grid;
    gap: 0.2rem;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
    grid-template-columns: repeat(auto-fit, minmax(450px, 1fr));
    z-index: 0;
    @media #{$max-xs} {
      grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
    }
    &-item {
      padding: 2rem 2rem 3rem;
      position: relative;
      cursor: pointer;
      overflow: hidden;
      &:first-child {
        background-color: $light-blue;
        &::after {
          background-color: $light-blue;
        }
      }
      &:nth-child(2) {
        background-color: $light-grey3;
        &::after {
          background-color: $light-grey3;
        }
      }
      &:nth-child(3) {
        background-color: $light-grey;
        &::after {
          background-color: $light-grey;
        }
      }
      &:nth-child(4) {
        background-color: $light-blue2;
        &::after {
          background-color: $light-blue2;
        }
      }
      &:nth-child(5) {
        background-color: $light-brown;
        &::after {
          background-color: $light-brown;
        }
      }
      &::after {
        content: 'Διαβάστε περισσότερα';
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        opacity: 0;
        transform: translateX(-100%);
        transition: transform 0.5s $cubic-out, opacity 0.5s $cubic-out;
        z-index: 1;
        display: grid;
        place-content: center;
        font-size: 1.3rem;
        font-weight: 300;
      }
      &:hover {
        &::after {
          transform: translateX(0);
          opacity: 0.96;
        }
        .tomeis-item-title {
          transform: translateX(-50%);
          margin-left: 50%;
          &::after {
            opacity: 0.4;
            width: 100%;
          }
        }
      }
      &-inner {
        display: flex;
        flex-direction: column;
        gap: 0.4rem;
      }
      &-title {
        font-size: 1.6rem;
        font-weight: $normal;
        color: $black;
        position: relative;
        width: fit-content;
        margin-bottom: 1rem;
        z-index: 2;
        transition: all 0.5s $cubic-out;
        @media #{$max-xs} {
          width: max-content;
        }
        &::after {
          content: '';
          position: absolute;
          left: 0;
          bottom: -0.3rem;
          border: 1px solid $black;
          width: 0rem;
          height: 1px;
          transition: width 0.5s $cubic-out;
          opacity: 0;
        }
      }
      &-text {
        font-family: $manrope;
        font-size: 1.1rem;
        line-height: 1.5;
      }
      &:nth-child(even) .title {
        order: 1;
      }
    }
  }
}
