@import '../../../styles/variables';

.biografikoSection {
  padding-top: 5rem;
  padding-bottom: 7rem;
  background-color: $light-grey2;
  .container {
    max-width: $layout-width;
  }
  .inner {
    max-width: 90rem;
  }
  &-title {
    max-width: 50rem;
    font-size: 2rem;
    font-weight: $light;
    position: relative;
    margin-bottom: 2rem;
    line-height: 1.3;

    &::after {
      content: '';
      position: absolute;
      left: 0;
      bottom: -0.3rem;
      border: 1px solid $black;
      width: 1.4rem;
      height: 1px;
      transition: width 0.5s $cubic-out;
      opacity: 0.4;
    }
  }
  &-text {
    // padding-top: 2rem;

    font-family: $manrope;
    font-size: 1.2rem;
    line-height: 1.4;
    font-weight: $light;
    p {
      padding-bottom: 0.7rem;
    }
    &.shortText {
      transition: max-height 0.5s $cubic-out;
      height: 100%;
      max-height: 0;
      overflow: hidden;
      &.isVisible {
        max-height: 45rem;
      }
    }
  }
  &-footer {
    display: flex;
    justify-content: flex-end;
    &-link {
      font-size: 1.2rem;
      cursor: pointer;
      transition: opacity 0.3s $cubic-out;
      &:hover {
        opacity: 0.5;
      }
    }
  }
}
