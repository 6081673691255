$layout-width: 1600px;
$content-width: 900px;
$layout-width-small: 1300px;
/**
  Media
  ===================================================================
*/
$width-xxs: 375;
$width-xs: 569;
$width-s: 768;
$width-m2: 1024;
$width-m: 1180;
$width-l: 1280;
$width-xl: 1600;
$width-xxl: 1920;
$xs: 'only screen and (min-width: 321px)';
$s: 'only screen and (min-width: 569px)';
$m: 'only screen and (min-width: 768px)';
$l: 'only screen and (min-width: 1180px)';
$xl: 'only screen and (min-width: 1280px)';
$xxl: 'only screen and (min-width: 1600px)';
$canvas: 'only screen and (min-width: 768px) and (min-height: 600px)';
$mobileLandscape: 'only screen and (max-width: 768px) and (max-height: 375px)';
$print: 'print';
$max-xxs: 'only screen and (max-width: #{$width-xxs }px)';
$max-xs: 'only screen and (max-width: #{$width-xs - 1}px)';
$max-s: 'only screen and (max-width: #{$width-s}px)';
$max-m: 'only screen and (max-width: #{$width-m}px)';
$max-m2: 'only screen and (max-width: #{$width-m2}px)';
$max-l: 'only screen and (max-width: #{$width-l - 1}px)';
$max-xl: 'only screen and (max-width: #{$width-xl - 1}px)';
$h-xs: 'only screen and (min-height: 640px)';
$h-s: 'only screen and (min-height: 860px)';
/**
  Colors
  ===================================================================
*/
$yellow: #ffcc00;
$yellow-2: #ffcd34;
$yellow-3: #ffcc33;

$white: #ffffff;
$black: #1a1a1a;
$dark-grey: #444444;
$dark-grey2: #343b3b;
$grey: #f9f7f7;
$grey-2: #c6c6c6;
$grey-3: #f9f7f7;
$grey-4: #f2f2f2;

$blue: #3b5e8a;
// $light-blue: #91bed4;
$blue-grey: #50636d;

$light-blue: #e9eef5;
$light-blue2: #d8e2ef;
$light-blue3: #daedf4;
$light-brown: #edebdf;
$light-grey: #d6d6cf;
$light-grey2: #f6f6f6;
$light-grey3: #ebebeb;
$light-grey4: #f9f9f9;
/**
  Typography
  ===================================================================
*/
$roboto: 'Roboto', sans-serif;
$manrope: 'Manrope', sans-serif;

$default-font: $roboto;
$extralight: 200;
$light: 300;
$normal: 400;
$medium: 500;
$semibold: 600;
$bold: 700;
$extrabold: 800;

$cubic-out: cubic-bezier(0.25, 0.46, 0.45, 0.94);
