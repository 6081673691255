@import '../../styles/variables';

footer {
  display: flex;
  flex-direction: column;
  width: 100vw;
  .footer-1 {
    background-color: $light-grey;
    color: $white;
    padding: 2rem;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-size: 1.063rem;
    transition: none;
    @media #{$max-s} {
      padding-left: 2rem;
      padding-right: 2rem;
    }
    @media #{$max-xs} {
      padding-left: 1rem;
      padding-right: 1rem;
    }
    .container {
      display: flex;
      justify-content: center;
      .column {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 0.4rem;
      }
      .logo {
        text-transform: uppercase;
        color: $black;
        font-size: 2.15rem;
        font-weight: $light;
        // letter-spacing: -3px;
        span {
          letter-spacing: normal;
          font-weight: $bold;
        }
      }
      .contactText {
        color: $black;
        padding-top: 0rem;
        padding-bottom: 0rem;
        font-size: 1.4rem;
        text-align: center;
        line-height: 1.5;
        max-height: 0;
        opacity: 0;
        transition: all 0.8s $cubic-out;
        @media #{$max-xs} {
          font-size: 1.2rem;
        }
      }
      .contactInfo {
        padding-top: 1rem;
        display: flex;
        gap: 0.5rem;
        font-size: 1rem;
        @media #{$max-xs} {
          flex-direction: column;
          align-items: center;
          justify-content: center;
        }
        &-column {
          display: flex;
          flex-direction: column;
          gap: 0.7rem;
          align-items: flex-end;
          position: relative;
          @media #{$max-xs} {
            padding-right: 0;
            align-items: center;
          }

          &:first-child {
            padding-right: 1.4rem;
            @media #{$max-xs} {
              padding-right: 0;
              align-items: center;
            }
            &::after {
              content: '';
              width: 1px;
              height: 100%;
              background-color: $grey-2;
              position: absolute;
              top: 0;
              right: 0;
              bottom: 0;
              @media #{$max-xs} {
                display: none;
              }
            }
          }
          &:last-child {
            padding-left: 1rem;
            @media #{$max-xs} {
              padding-left: 0;
              align-items: center;
            }
          }
        }
        &-row {
          display: flex;
          gap: 0.5rem;
          align-items: center;
          color: $black;
          a:hover {
            color: $white;
          }
          &-icon {
            width: 1.2rem;
            height: 1.2rem;
            svg {
              width: 100%;
              height: 100%;
              path {
                fill: $black;
              }
            }
          }
        }
      }
    }
  }
  .contactMap {
    max-height: 0;
    height: 0;
    overflow: hidden;
    transition: all 0.8s $cubic-out;
  }
  .footer-2 {
    background-color: $black;
    color: $white;
    padding: 2rem;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-size: 1.063rem;
    @media #{$max-s} {
      padding-left: 2rem;
      padding-right: 2rem;
    }
    @media #{$max-xs} {
      padding-left: 1rem;
      padding-right: 1rem;
    }
    .container {
      display: flex;
      justify-content: center;

      @media #{$max-xs} {
        flex-wrap: wrap;
        gap: 0.5rem;
      }

      .footer-nav {
        &-item {
          &:before {
            content: '|';
            padding: 0 0.5rem;
          }
          &:first-child {
            &:before {
              @media #{$max-xs} {
                content: '';
              }
            }
          }
        }
      }
    }
  }
}
