@import '../../styles/variables';

.loader-container {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  z-index: 100;
  display: grid;
  // background-color: rgba(0, 0, 0, 0.4);
  place-content: center;
  overflow: hidden;
  visibility: visible;
  transition: opacity 0.3s ease-out;
}
.loader,
.loader:after {
  border-radius: 50%;
  width: 6rem;
  height: 6rem;
}
.loader {
  margin: 60px auto;
  font-size: 10px;
  position: relative;
  text-indent: -9999em;
  border-top: 1.1em solid $grey-2;
  border-right: 1.1em solid $grey-2;
  border-bottom: 1.1em solid $grey-2;
  border-left: 1.1em solid $blue;
  transform: translateZ(0);
  animation: load8 1.1s infinite linear;
  overflow: hidden;
  padding-top: 50%;
  transform: translateX(50%);
}
@-webkit-keyframes load8 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes load8 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
