@import '../../styles/variables';

.home {
  // .container {
  //   width: 100%;
  //   display: flex;
  //   justify-content: flex-start;

  // }
  display: flex;
  flex-direction: column;
  .hero {
    width: 100vw;
    height: 43rem;
    display: flex;
    align-items: center;
    background-size: cover;
    background-position: center;
    position: relative;
    background-color: #545454;
    transition-delay: 0.6s;
    &::after {
      content: '';
      position: absolute;
      right: 0;
      top: 0;
      bottom: 0;
      width: 40rem;
      background-image: url('/assets/images/image-transparency.png');
      z-index: 0;
      background-size: cover;
      opacity: 0.6;
    }
    @media #{$max-s} {
      height: unset;
      padding-top: 4rem;
      padding-bottom: 4rem;
      background-position: center right;
    }
    &-inner {
      display: flex;
      justify-content: flex-end;
      z-index: 1;
      padding-right: 3rem;
    }
    &-content {
      max-width: 28.8rem;
      @media #{$max-s} {
        max-width: 100%;
      }
      &-title {
        text-transform: uppercase;
        color: $white;
        font-size: 3.15rem;
        font-weight: $light;
        letter-spacing: -3px;
        text-shadow: 0px 1px 0px rgb(0 0 0 / 70%);
        @media #{$max-s} {
          font-size: 2.65rem;
        }
        span {
          font-weight: $extrabold;
          letter-spacing: 3px;
          padding-left: 0.5rem;
        }
      }
      &-subtitle {
        color: $white;
        font-family: $manrope;
        font-size: 1.33rem;
        font-weight: 300;
        padding-bottom: 1rem;
        text-align: right;
        @media #{$max-s} {
          text-align: left;
        }
      }
      &-text {
        font-family: $manrope;
        color: $white;
        font-size: 1.7rem;
        width: 100%;
        line-height: 1.3;
        font-weight: $normal;
        text-shadow: 0px 1px 0px rgb(0 0 0 / 70%);
      }
    }

    img {
      opacity: 1;
      position: absolute;
      left: 0px;
      top: 0px;
      width: 50%;
      height: 100%;
      object-fit: cover;
      object-position: left center;
    }
  }
}
