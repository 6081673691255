@import '../../styles/variables';

.modal {
  position: fixed;
  // max-width: max-content;
  padding: 1rem;
  background-color: rgba(255, 255, 255, 0.08);
  border-radius: 0.8rem;
  z-index: 4;
  bottom: 1rem;
  right: 1rem;
  box-shadow: 0 0 1.3125rem 0.1875rem rgb(0 0 0 / 10%);

  transition: width 0.4s $cubic-out;
  &.minimize {
    transition: width 0.4s $cubic-out;
    .modal-body {
      opacity: 0;
      width: 100%;
      max-width: 0.875rem;
      transform: translateX(3rem);
      transition: max-width 0.6s $cubic-out, opacity 0.6s $cubic-out, transform 0.6s $cubic-out 0.5s;
      a {
      }
    }
    .modal-close {
      &:hover {
        transform: scale(0.9);
      }
      span:nth-child(1) {
        transform: rotate(90deg) translate(-6px, -3px);
        width: 70%;
      }
      span:nth-child(2) {
        transform: rotate(0) translate(9px, 10px);
        width: 4px;
        height: 4px;
        border-radius: 2rem;
      }
    }
  }
  &-body {
    text-align: center;
    transition: max-width 0.6s $cubic-out, opacity 0.6s $cubic-out, transform 1s $cubic-out 0;

    transform: translateX(0);

    max-width: 100%;
  }
  a {
    font-size: 1.1rem;
    color: #706f6f;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 100%;
    margin-right: 1.8rem;
    transition: color 0.4s $cubic-out;
    &:hover {
      color: #fdca31c4;
    }
  }
  &-close {
    position: absolute;
    right: 0.8rem;
    top: 0.8rem;
    width: 1.3rem;
    height: 1.2rem;
    background: none;
    border: 0px;
    font-weight: bold;

    cursor: pointer;
    z-index: 2;
    transition: transform 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
    &:hover {
      transform: rotate(180deg);
      opacity: 0.6;
    }
    span {
      width: 100%;
      background: #706f6f;
      height: 2px;
      display: block;
      position: absolute;
      transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    }
    span:nth-child(1) {
      transform: rotate(-45deg);
    }
    span:nth-child(2) {
      transform: rotate(45deg);
    }
  }
}
